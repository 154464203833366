@import '../sizes';

.auth_screen {
  display: grid;
  height: var(--heightScreen);
  grid-template-rows: auto 1fr auto;
  box-sizing: border-box;
  overflow: auto;

  background-image: url('../../../public/auth/auth_bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 48px;
  @media screen and (max-width: $mobileWidthMedia2) {
    padding: 10px;
  }

  .auth_logo {
    display: flex;
    justify-content: flex-start;
    grid-gap: 15px;

    img:first-child {
      width: 50vw;
      max-width: 220px;
    }

    img:nth-child(2) {
      max-width: 350px;
      width: 100%;
      max-height: 43px;
    }

    img:nth-child(3) {
      display: none;
    }

    @media screen and (max-width: 1270px) {
      flex-direction: column;
      img:nth-child(1) {
        max-width: 140px;
      }
      img:nth-child(2) {
        display: none;
      }
      img:nth-child(3) {
        display: block;
        max-width: 275px;
      }
    }
  }

  .auth_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;
    text-align: center;
    font-family: ALS Wagon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 1;
    img {
      padding-bottom: 16px;
    }

    @media screen and (max-width: $mobileWidthMedia2) {
      padding-bottom: 20px;

      img {
        width: 150px;
      }
    }
  }

  .auth_content {
    width: calc(100vw - 48px * 2);
    max-width: 315px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: $mobileWidthMedia2) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .auth_title {
      color: white;
      font-family: ALS Wagon;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding-bottom: 24px;
      @media screen and (max-width: $mobileWidthMedia2) {
        padding-bottom: 8px;
      }
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input input {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.05) !important;
      backdrop-filter: blur(75px);
      border-color: rgba(255, 255, 255, 0.2);
      border-width: 2px;
      color: #fff;

      &::placeholder {
        color: #fff;
        font-family: ALS Wagon;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
      }

      &:hover,
      &:active,
      &:focus {
        border-color: rgba(255, 255, 255, 1);
        border-width: 2px;
      }
    }

    .ant-input-affix-wrapper {
      background-color: transparent;
    }

    .ant-form-item-explain-error {
      color: #ea6464;
    }

    .ant-form-item-label label {
      color: white !important;
      font-family: ALS Wagon;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.5;
    }

    button {
      border-radius: 4px;
      height: 39px;
      border: none;

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: ALS Wagon;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        position: relative;
        //top: 3px;
      }

      &:disabled {
        background: #9db1d7;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .iconPass {
      position: relative;
      top: -45px;
      margin-left: 290px;
      color: white;
      cursor: pointer;

      @media only screen and (max-width: 450px) {
        margin-left: calc(100vw - 48px - 48px - 50px);
      }
    }

    .ant-form-item-has-error + .iconPass {
      top: -50px;
    }

    .tooltip_form {
      color: #fff;
      font-size: 16px;
      font-weight: 400;

      padding-top: 24px;

      max-width: 310px;
    }

    @media screen and (max-width: $mobileWidthMedia) {
      width: calc(100vw - 60px * 2);

      .auth_title {
        padding-left: 0;
      }

      button {
        margin-left: 0;
      }

      .tooltip_form {
        padding-left: 0;

        max-width: calc(100vw - 90px);
        position: relative;
      }
    }
  }

  &.error {
    background-image: url('../../../public/auth/auth_bg_2.svg');

    .error_message {
      border-radius: 4px;
      background: #ea6464;

      color: #fff;
      font-family: ALS Wagon;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 19.8px */

      margin-left: 80px;
      padding: 12px 20px;

      margin-bottom: 32px;
    }
  }
}
