@import 'sizes';
@import 'font/stylesheet.css';
@import '../styleguides.css';
@import 'shared';
@import 'components_styles/header_component';
@import "components_styles/modal_add_contracts";
@import "components_styles/upload_file.scss";
@import "components_styles/moda_view_contract.scss";
@import "components_styles/modal_errors.scss";
@import "components_styles/modal_contracts_agreement.scss";
@import "components_styles/modal_select_contragents.scss";

html,
body {
  margin: 0;
  background: #f0f1f6;
  font-family: ALS Wagon;
}

#root {
  min-height: var(--heightScreen);

  --menu-width: 10px;
  --app-content-header-height: 10px;
  --form-width: 800px;
  font-family: ALS Wagon;

  @media (max-width: 1250px) {
    --menu-width: 10px;
  }

  .app-layout {
    min-height: var(--heightScreen);

    .app-menu {
      background: var(--neutral-2, #fafafa);
      width: var(--menu-width, 250px);
      height: var(--heightScreen);
      position: fixed;
      top: 0;
      left: 0;

      .logo {
        height: 68px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 24px;
        text-decoration: none;

        img {
          width: 120px;
          height: 21px;
        }
      }

      .menu-item {
        padding: 8px 8px 8px 16px;
        height: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        grid-gap: 10px;

        margin-left: 12px;
        margin-right: 12px;

        color: var(--neutral-13, #000);

        /* H5/regular */
        font-family: Noto Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        text-decoration: none;

        &.active {
          background: var(--neutral-5, #d9d9d9);
          border-radius: 8px;
        }
      }

      .menu-item-icon {
        background: var(--hit-box, rgba(255, 255, 255, 0));
      }

      .menu-item-logout {
        position: fixed;
        bottom: 0;
      }
    }

    .app-content-header {
      position: fixed;
      top: 0;
      left: var(--menu-width, 250px);
      width: calc(100vw - var(--menu-width, 250px));
      box-sizing: border-box;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: #fff;

      padding: 12px 32px;
      z-index: 100;

      .ant-avatar {
        background-color: var(--pnk-color-3, #ffedd4);
        color: var(--pnk-color-6, #f09f54);
      }

      .app-content-title {
        display: flex;
        flex-direction: column;
        padding-left: 12px;

        span:nth-child(1) {
          color: var(--neutral-9, #434343);

          /* H4/medium */
          font-family: Noto Sans SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 140% */
        }

        span:nth-child(2) {
          color: var(--neutral-7, #8c8c8c);

          /* Footnote/description */
          font-family: Noto Sans SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .app-content-title2 {
          /* H4/medium */
          font-family: Noto Sans SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 140% */

          a {
            text-decoration: none;

            color: var(--character-secondary-45, #434343);
          }

          .app_current_breadcrumb {
            color: var(--neutral-9, #434343);
          }
        }
      }

      .app-content-avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          padding-left: 10px;
          padding-right: 30px;

          min-width: 170px;
          max-width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        button {
          display: flex;
          align-items: center;
        }
      }
    }

    .app-content {
      // margin-left: calc(var(--menu-width, 250px) + 24px);
      // margin-top: calc(var(--app-content-header-height, 80px) + 20px);
      padding: 20px;

      box-sizing: border-box;
      top: 0;
      left: 0;
      // width: calc(100vw - var(--menu-width, 250px) - 48px - 16px);
      width: calc(100vw - 16px);
      min-height: calc(var(--heightScreen) - var(--app-content-header-height, 80px) - 100px);

      background-color: #fff;

      &.bg_opacity {
        background-color: transparent;
      }
    }
  }
}

.ant-pagination {
  text-align: right;
}

.ant-pagination-options {
  float: left;
}

@font-face {
  font-family: 'ALS Wagon Regular';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Regular_1.1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Regular_1.1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon Medium Narrow';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Medium-Narrow_1.1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon Regular Narrow';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Regular-Narrow_1.1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon Medium';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Medium_1.1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: "ALS Wagon";
//  src: url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.eot");
//  src: url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.eot?#iefix") format("embedded-opentype"),
//  url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.woff") format("woff"),
//  url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.woff2") format("woff2"),
//  url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.ttf") format("truetype"),
//  url("../public/ALS_Wagon/Web Fonts/aea834bf4d55550ecc3829d074de4991.svg#ALS Wagon Regular") format("svg");
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'ALS Wagon Bold';
  src: url('../../public/ALS_Wagon/ALS_Wagon_Medium_1.1.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.ant-table {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;

  th {
    background: none !important;

    &:first-child {
      border-start-start-radius: 0 !important;
    }

    &:last-child {
      border-start-end-radius: 0 !important;
    }
  }

  th,
  td {
    padding: 8px !important;

    border-top: 1px solid var(--Borders, #d1d2d6);
    border-left: 1px solid var(--Borders, #d1d2d6);
  }

  tr:first-child th, tr td {
    &:first-child {
      border-left: none;
    }
  }

  tr:last-child td {
    border-bottom: 1px solid var(--Borders, #d1d2d6) !important;
  }
}

.ant-modal-content {
  border-radius: 4px !important;

  &,
  .ant-modal-title {
    background: #f0f1f6 !important;
  }

  .ant-modal-title {
    color: #000;
    font-family: ALS Wagon;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h4 {
    color: #000;
    font-family: ALS Wagon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.ant-form-item-label {
  label {
    color: #000;
    font-family: ALS Wagon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;

    &::after {
      content: ' ' !important;
    }
  }
}

.ant-modal-confirm-title {
  color: #000;
  font-family: ALS Wagon;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-modal-confirm-btns {
  text-align: start !important;
}

.SuperEditDate input {
  font-size: 18px !important;
}

.ant-btn {
  border-radius: 4px;
  font-size: 18px;
  height: auto;

  &.ant-btn-default {
    background: rgba(0, 74, 215, 0.1);
    color: var(--primary);
  }

  &.ant-btn-primary[disabled] {
    color: white;
    background: rgba(0, 74, 215, 0.3);
  }
}

.ant-btn-dangerous {
  background: var(--Red, #eb5757) !important;
  color: white !important;

  &.ant-btn-dashed {
    color: var(--Red, #eb5757) !important;
    background-color: rgba(235, 87, 87, 0.08) !important;
  }
}

.ant-input,
.ant-select-selector,
.ant-picker {
  border-radius: 4px !important;
}

.ant-modal-mask {
  background: url('../../public/layout/bd_grey2.svg') !important;
  background-size: cover !important;
}

.modal-custom-com {
  &::after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }
}

.load {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 9999;

  //background-color: rgba(0, 0, 0, 0.2);

  img {
    width: 60px;
  }
}


