.not_found {
  background: url('../../../public/layout/bd_grey.svg') no-repeat center;
  background-size: cover;
  height: var(--heightScreen);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .not_found_number {
    color: #fff;
    text-align: center;
    font-family: ALS Wagon;
    font-size: 160px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .not_found_text {
    color: #fff;
    text-align: center;
    font-family: ALS Wagon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
