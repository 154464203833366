.modal-add-contract {
  &.ant-modal {
    width: 700px !important;

    .ant-modal-header {
      margin-bottom: 24px;

      .ant-modal-title {
        font-size: 24px;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      border-radius: 12px;

      .ant-form-item-label label::after {
        content: ':' !important;
      }

      .ant-select {
        width: 100% !important;
      }
    }
  }

  .modal-add-contract__list {
    margin-bottom: 16px;
  }
}

.modal-add-contract-form {
  border-top: 1px solid rgba(182, 160, 160, 0.42);
  padding-top: 12px;

  border-bottom: 1px solid rgba(182, 160, 160, 0.42);
  padding-bottom: 12px;
  margin-bottom: 12px !important;

  & + & {
    border-top: none !important;
    padding-top: 0;
  }

  .ant-form-item-label label::after {
    content: ':' !important;
  }

  .ant-form-item-explain-connected {
    padding-bottom: 10px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 55px;

    div {
      font-weight: 400;
      font-size: 18px;
    }

    .anticon {
      font-size: 15px;
      color: red;
    }
  }

  &__info {
    background-color: #004AD714;
    border: 1px solid #004AD73D;
    padding: 12px;
    border-radius: 6px;

    margin-bottom: 32px;

    span {
      display: inline-block;
      width: 120px;
      opacity: 0.6;
      color: #100F1F;

      @media screen and (max-width: 768px) {
        display: block;
        width: 100%;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control-input {
    margin-bottom: 24px;

    & + div {
      margin-top: -24px;
    }
  }
}

.modal-add-bill {
  &__nomenclature {
    font-weight: 600;
    font-size: 18px;
  }

  &__nomenclature_row {
    font-weight: 400;
    font-size: 16px;
  }

  &__nomenclature_row + &__nomenclature {
    margin-top: 16px;
  }

  &__waybills {
    background-color: white;
    border-radius: 6px;
    padding: 8px 8px 0;
  }

  &__waybill-line {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    span {
      padding-right: 4px;
    }
  }
}
