@import '../sizes';

.PassportList {
  font-family: ALS Wagon;

  .wrap_table {
    padding: 20px 32px;
    width: 100%;
    //max-width: 1250px;
    box-sizing: border-box;
    .ant-table-cell {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 400;
    }

    .ant-table-content {
      overflow-x: auto;
    }

    table {
      th:nth-child(1) {
        min-width: 300px;
        width: 100%;
      }

      th:nth-child(2) {
        min-width: 150px;
      }

      th:nth-child(3) {
        min-width: 200px;
      }

      th:nth-child(4) {
        max-width: 120px;
        min-width: 120px;
      }

      th:nth-child(5) {
        min-width: 150px;
        max-width: 150px;
      }

      th:nth-child(7) {
        max-width: 120px;
        min-width: 120px;
      }

      tr:nth-child(2) th:nth-child(1) {
        min-width: 150px;
        max-width: 150px;
      }

      tr:nth-child(2) th:nth-child(2) {
        min-width: 150px;
        max-width: 150px;
      }

      tr:nth-child(2) th:nth-child(3) {
        min-width: 150px;
        max-width: 150px;
      }

      tr:nth-child(2) th:nth-child(5) {
        min-width: 150px;
        max-width: 150px;
      }

      //tr:nth-child(1) td {
      //  font-weight: 500;
      //}

      //th:nth-child(4) {
      //  text-align: right;
      //}
      //
      //td:nth-child(4) {
      //  text-align: right;
      //}
      //
      //th:nth-child(5) {
      //  text-align: right;
      //}
      //
      //td:nth-child(5) {
      //  text-align: right;
      //}

      a {
        color: #004ad7;
      }
    }

    .text-link {
      color: #004ad7;
    }

    @media screen and (max-width: 800px) {
      padding: 20px 0;

      .ant-table-content {
        width: calc(99vw);
        overflow-x: auto;

        table {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    .table_active table {
      tbody tr:nth-child(1) td {
        font-weight: bold;
      }
    }

    .table_footer {
      display: grid;
      grid-template-columns: 1fr 250px 240px;

      padding-top: 18px;

      color: #000;
      font-family: ALS Wagon;
      font-size: 18px;
      font-weight: 500;

      div:first-child {
        margin-left: 70px;
      }
    }
  }

  .btn_list_archive {
    //margin-top: 37px;

    color: var(--Gray-3);
    font-size: 18px;
    font-weight: 400;

    @media screen and (max-width: 800px) {
      padding: 24px 12px;

      span {
        border-bottom: 1px dashed var(--Gray-3);
      }
    }
  }

  .no_projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 80vh;

    .text {
      color: var(--Gray-3);
      text-align: center;
      font-size: 21px;
      font-weight: 400;

      margin-top: 6px;
      margin-bottom: 24px;
    }
  }
}

.create_project_modal {
  .ant-form-item {
    width: 100%;
  }

  .member_row,
  .section_row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-form-item {
    }

    .ant-form-item-control {
      width: 184px;
      max-width: 184px;
    }

    .icon_remove {
      margin-bottom: 24px;
      margin-left: 10px;
      color: #ff380d;
      position: relative;
      left: -38%;
    }

    @media screen and (max-width: 800px) {
      .ant-form-item-control {
        width: 100%;
        max-width: 100%;
      }

      .icon_remove {
        margin-bottom: 24px;
        margin-left: 10px;
        color: #ff380d;
        position: relative;
        left: 0;
      }
    }

    @media screen and (max-width: $mobileWidthMediaMini2) {
      .icon_remove {
        top: 20px;
      }
    }
  }

  .ant-modal-footer {
    text-align: end;

    .ant-btn-default {
      display: none;
    }
  }
}

.passport-list-mb-header {
  font-family: 'ALS Wagon';

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 24px;

  .title {
    color: var(--Gray-3);
    font-size: 18px;

    font-weight: 400;
  }

  .value {
    color: #000;
    font-size: 18px;

    font-weight: 500;
  }
}

.passport-list-mb-table {
  font-family: 'ALS Wagon';
  padding: 0 12px;

  .passport-list-mb-row {
    border-top: 1px solid var(--Borders, #d1d2d6);
    padding: 8px;

    .title-line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: var(--primary);
        text-decoration: none;

        font-size: 18px;
        font-weight: 400;
      }
    }

    .plan-line {
      display: flex;
      padding: 4px 0;

      font-size: 18px;
      font-weight: 400;
    }

    .location-line {
      color: var(--Gray-3);
      font-size: 18px;
      font-weight: 400;
    }
  }
}
