
.contracts-agreement {
  font-family: 'ALS Wagon', serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;

  &__modal {
    //.ant-table-cell {
    //  color: red;
    //}

    .ant-table-row.green td {
      background-color: #BBFFCE !important;
    }
    .ant-table-row.green:hover td {
      background-color: #71e391 !important;
    }

    .ant-table-row.yellow td {
      background-color: #fff7af !important;
    }

    .ant-table-row.yellow:hover td {
      background-color: #fded61 !important;
    }


    .ant-table-row.black td {
      background-color: rgba(128, 128, 128, 0.8);
      color: whitesmoke;
    }
    .ant-table-row.black:hover td {
      background-color: rgba(128, 128, 128, 0.9);
    }

  }

  &__header {
    margin-bottom: 10px;

    .line {
      margin-bottom: 5px;
    }

    .line-title {
      font-weight: 400;

      color: #000000;

      opacity: 0.5;
    }

    .line-value {
      font-weight: 600;
      color: #000000;
    }
  }
}
