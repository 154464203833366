.notifications_screen {
  .notificationsTable {
    padding-left: 24px;
    padding-right: 24px;

    max-height: calc(100vh - 80px);
    overflow: auto;
  }

  .notification_row_mobile_wrap {
    max-height: calc(100vh - 100px);
    overflow: auto;
    padding-bottom: 80px;

    .notification_row_mobile {
      padding-top: 8px;
      margin: 8px 15px;

      box-sizing: border-box;
      border-top: var(--Borders, #d1d2d6) 1px solid;

      .title {
        display: flex;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.5);

        .comment {
        }

        .time {
        }
      }

      .message {
        padding-top: 4px;
        max-width: calc(100vw - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.loading {
      .notification_row_mobile {
        .comment,
        .message {
          color: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
  }
}
