.PricesList {
  .ant-table th, .ant-table td {
    border-left: 1px transparent !important;
    border-right: 1px transparent !important;
  }


  .prices-table {

    .ant-table-thead {
      font: 14px ALS Wagon Bold;
    }

    .ant-table-cell {
      border: 0.5px solid #D1D2D6 !important;

      &:has(.section) {
        background-color: #D7F1FF;
      }
      &:has(.work_type) {
        background-color: #F0F1F6;
      }
      &:has(.nomenclature) {
        background-color: #F0F1F6;
      }

      &:has(input.ant-input.nomenclature) {
        padding: 0 !important;
      }
    }

    &__title {
      display: flex;
      align-items: center;

      &.section {
        font: 16px ALS Wagon Bold;
      }

      &.work_type {
        font: 16px ALS Wagon Bold;
        padding-left: 20px;
      }

      &.nomenclature {
        padding-left: 45px;
      }
    }

    input.ant-input.nomenclature {
      transition: none !important;
      border-radius: 0 !important;
      height: 38px;
      border: none;

      &[readonly] {
        background-color: rgba(198, 198, 198, 0.01);
      }
    }

  }
}
