:root {
  --visibleHeader: flex;
  --visibleHeaderHeight: calc(80px + 120px);
  //--visibleHeader: none;
}

.header_component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-family:
    ALS Wagon,
    serif;

  .header_component_right {
    display: flex;
    grid-gap: 12px;
    align-content: center;
    justify-content: center;

    .active {
      opacity: 0.3;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a:last-child {
      padding-right: 5px;
    }
  }

  .header_component__btn_add {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;

    font-weight: 400;
    font-size: 18px;
    color: #004ad7;

    .anticon {
      top: 0 !important;
    }
  }

  &__menu_icon {
    //padding-right: 12px;
    cursor: pointer;
    position: relative;
    left: 5px;
  }

  .header_component_logo {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    img.logo_header {
      margin-bottom: -6px;
    }

    .ant-btn-link {
      span {
        color: #004ad7;
        font-family:
          ALS Wagon,
          serif;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .ant-dropdown-trigger {
    height: auto;

    .anticon {
      position: relative;
      top: -5px;
    }
  }

  .btn_show_errors {
    padding-right: 0;
    padding-left: 12px;
  }

  .btn_show_contracts_agreement {
    padding-right: 0;
    padding-left: 12px;
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      padding: 3px 10px;
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 0;

    &.header_component__contragents {
      margin-bottom: 30px;
    }

    .btn_create_project {
      position: absolute;
      top: 50px;
      left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .ant-dropdown-trigger {
      display: flex;
      align-items: flex-start;

      .anticon {
        position: relative;
        top: 14px;
      }
    }
  }

  &__drawer {
  }

  &__drawer_logo {
    height: 16px;
    position: relative;
    top: 2px;
  }

  &__drawer_menu {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    a {
      font-size: 22px;
      color: #004ad7;
      font-weight: 600;
    }
  }

  &__error_count {
    &::before {
      content: " ";
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background-color: red;

      display: block;

      margin-bottom: -17px;
      margin-left: 2px;
    }

    position: absolute;
    top: 0px;
    left: 25px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    width: 20px;
    height: 20px;

  }



  //* BREABCRUMB */
  a.ant-breadcrumb-link {
    color: #004ad7;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
      background: none;
    }
  }

  span.ant-breadcrumb-link {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    word-break: break-all;
  }

  .ant-breadcrumb,
  .header_component_logo {
    margin-right: auto;
  }

  .ant-breadcrumb {
    margin-left: 10px;
  }

  @media screen and (max-width: 800px) {
    .ant-dropdown-trigger {
      //width: 100vw;
      //margin-right: -115px;
      //
      //word-break: break-all;
      //max-width: 100%;

      span.ant-breadcrumb-link {
        //max-width: 85vw;
      }
    }
  }

  .ant-breadcrumb-separator {
    position: relative;
    top: 6px;

    @media screen and (max-width: 600px) {
      visibility: hidden;
    }
  }

  //* END BREADCRUMB */
}

.header_component__btn_add {
  font-weight: 400;
  font-size: 18px;
  color: #004ad7;

  .anticon {
    top: 0 !important;
  }
}

.header_component__canvas-btns {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .header_component__canvas-btns {
    margin-bottom: 30px;
    justify-content: flex-end;

    button {
      padding-right: 5px;
    }
  }
}


.header_component__dropdown ul {
  width: 200px;
  margin-top: 20px;
}

