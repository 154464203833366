.DirectionList {
  .direction-table {
    font-family: 'ALS Wagon', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    color: #000000;

    .ant-table {
      font-size: 18px;
      height: calc(100vh - var(--visibleHeaderHeight) - 100px);
    }

    .ant-table-thead {
      font-family: 'ALS Wagon', serif;
      font-weight: 400;
      color: #000000;

      .ant-table-cell {
        height: 45px;
      }

      .direction-title {
        opacity: 0.48;
      }
    }

    .ant-table-cell {
      border: none !important;
      border-bottom: 0.5px solid #D1D2D6 !important;
      padding: 4px 8px !important;
    }

    .wrap_btns {
      float: right;

      .anticon {
        opacity: 0.6;
        cursor: pointer;
        display: inline-block;
        padding: 5px;
      }
    }
  }

}

.modal_direction {
  .modal_btns {
    padding-top: 10px;
    float: right;
  }
}
