.modal-errors {
  &.ant-modal {
    width: 1000px !important;

    .ant-modal-header {
      margin-bottom: 24px;

      .ant-modal-title {
        font-size: 24px;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
    }
  }

  .modal-add-contract__list {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: 600;
      font-size: 16px;
      color: #000;
    }
  }

  &__item_code {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-right: 8px;
  }

  &__item_status {
    .ant-tag {
      border: 1px solid rgba(0, 74, 215, 0.4);
      color: #004ad7;
      border-radius: 4px;
      padding: 1px 6px;
      background: #dde4f4;
    }
  }

  &__item_desc {
    padding-top: 8px;
    padding-bottom: 8px;

    font-weight: 500;
    font-size: 16px;
    color: #000;
  }

  &__item_date {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #d1d2d6;

  }

  @media (max-width: 768px) {
    .modal-errors__title + .modal-errors__title {
      display: block;
    }
  }
}

.modal-error-view {
  &.ant-modal {
    width: 640px !important;

    .ant-modal-header {
      margin-bottom: 24px;

      .ant-modal-title {
        font-size: 24px;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
    }
  }

  .has_error {
    color: red;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    //padding-bottom: 8px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 5px;
  }

  &__subtitle2 {
    span {
      display: inline-block;
      width: 240px;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: 500;
      font-size: 16px;
      color: #eb5757;
    }
  }

  &__bp_line {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);

    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: 5px;

    span {
      text-align: center;
    }

    span:first-child {
      text-align: left;
    }

    span:last-child {
      text-align: right;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;

      span:first-child {
        grid-column: 1 / span 3;
      }

      span:nth-child(2) {
        text-align: left;
      }
    }
  }

  &__delimiter {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
    margin: 8px 0px 20px 0px;
    width: 100%;
  }

  &__split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &__error_title {
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }

  &__error_count {
    &::before {
      content: " ";
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background-color: red;

      display: block;

      margin-bottom: -17px;
      margin-left: -6px;
    }

    position: relative;
    top: -3px;
    display: inline-block;
    color: white;
    font-size: 10px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    margin-left: 10px;

    &.closed {
      &::before {
        background-color: #F08C19;
      }
    }
  }

  &__error_delete {
    position: relative;
    top: 3px;
    float: right;
    padding-right: 0;
  }

  &__error_contract {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr auto auto auto;

    &_title {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
    }

    span {
      text-align: center;

      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);

    }

    span:first-child {
      text-align: left;
    }

    span:last-child {
      text-align: right;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;

      &_title {
        grid-column: 1 / span 3;

        display: flex;
        justify-content: space-between;
      }

      span:nth-child(2) {
        text-align: left;
      }
    }
  }

  &__error_contract_line {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: 5px;

    span {
      text-align: center;

      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.56);
    }

    span:first-child {
      text-align: left;
    }

    span:last-child {
      text-align: right;
    }

    &.closed {
      .has_error {
        color: #F08C19;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;

      div:first-child {
        grid-column: 1 / span 3;
      }

      span:nth-child(2) {
        text-align: left;
      }
    }
  }

  &__error_download {
    margin-top: 8px;
  }

}

.modal-error-confirm {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
  padding: 8px;
  margin-top: 20px;

  border: 1px solid rgba(235, 87, 87, 0.24);
  border-radius: 6px;
  background: rgba(235, 87, 87, 0.04);

  line-height: 17px;


  &__title {
    width: 100%;

    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__approve {
    &.ant-btn-link span {
      font-weight: 400;
      font-size: 16px;
      text-align: right;
      color: #f08c19 !important;
    }
  }

  &__cancel span {
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    color: #eb5757;
  }

  &.level-warning {
    background-color: #F1EBD8;
    border: 1px solid #F08C193D;

    .ant-btn-default {
      background: #F08C19 !important;
      border: none !important;

      &:hover {
        background: rgba(240, 140, 25, 0.8) !important;
      }

      span {
        color: #F1EBD8;
      }

      &[disabled] {
        background: rgba(240, 140, 25, 0.5) !important;

        span {
          color: #F1EBD8;
        }
      }

    }
  }

  &.level-error {
    background-color: #EB57570A;
    border: 1px solid #EB57573D;

    .ant-btn-default {
      background: #EB5757 !important;
      border: none !important;

      &:hover {
        background: rgba(235, 87, 87, 0.8) !important;
      }

      span {
        color: #FFE0E0FF !important;
      }

      &[disabled] {
        background: rgba(235, 87, 87, 0.5) !important;

        span {
          color: #EB57570A;
        }
      }
    }
  }

  &.closed {
    background-color: #F08C193D;
  }

  &.two-line {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    .modal-error-confirm__buttons {
      justify-content: flex-end;

      button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 8px;

    &__approve {
      padding: 0;
    }

    &__buttons {
      justify-content: flex-start;

      button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.modal-error-contract-agreement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(235, 87, 87, 0.24);
  border-radius: 6px;
  background: rgba(235, 87, 87, 0.04);
  padding: 8px 16px;
  margin-top: 16px;

  &>span:nth-child(1) {
    font-family: 'ALS Wagon', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;

    img {
      padding-right: 5px;
    }
  }

  button {
    margin-left: 10px;
  }

  &>span:nth-child(2) {
    min-width: 250px;
    display: flex;
    justify-content: flex-end;
  }

  &.info {
    background: rgba(240, 140, 25, 0.04);
    border: 1px solid rgba(240, 140, 25, 0.24);
  }
}
