@import '../sizes';

.profile_screen {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 95vh;

  .avatar {
    width: 166px;
    height: 166px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(30vh - 100px);
    margin-bottom: 18px;

    img {
      width: 100%;
    }

    .user_fio {
      position: absolute;
      margin-top: - calc(166px / 2 + 50px);
      width: 166px;
      text-align: center;
      font-size: 70px;
      color: #4ca9ff;
      text-transform: uppercase;
    }
  }

  .full_name {
    color: #000;
    text-align: center;
    font-family: ALS Wagon;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .widget_container {
    text-align: center;
    margin-top: 10px;

    .telegram_auth {
      margin-top: 8px;
      background: none;
      border: none;
      cursor: pointer;

      color: #004ad7;
      font-family: ALS Wagon;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .telegram_auth_disconnect {
      margin-top: 8px;

      color: #eb5757;
      font-family: ALS Wagon;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      background: none;
      border: none;

      cursor: pointer;
    }
  }

  .email {
    margin-top: 4px;
    text-align: center;
    color: #000;
    font-family: ALS Wagon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .logout {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;

    position: relative;
    top: -50px;

    color: #eb5757;
    font-family: ALS Wagon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;

    display: flex;
    align-items: center;
  }
}
