.upload_file {
  .wrap_file {
    background: rgba(0, 74, 215, 0.06);
    box-sizing: border-box;
    cursor: pointer;
    color: var(--primary);
    border-radius: 4px;
    height: 32px;

    .wrap_file__title {
      position: absolute;
      cursor: pointer;

      height: 32px;
      padding: 6px 16px ;
      box-sizing: border-box;
    }

    .file {
      cursor: pointer;
      opacity: 0;
      height: 32px;
    }

    &.disabled {
      cursor: default;

      .file {
        cursor: default;
      }
    }

    &.no-bg {
      background: none;
    }

  }
  .wrap_list_files {
    margin-top: 10px;
  }

  .doc_line {
    display: flex;
    align-items: center;
    padding: 8px;

    div {
      //max-width: 170px;
      overflow-wrap: break-word;
      //width: 100%;
    }

    &.new {
      padding: 8px 16px;

      div {
        //max-width: 130px;
      }
    }

    color: #004ad7;

    font-family: ALS Wagon,
    serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .anticon {
      padding-left: 6px;
      color: #ff380d;
    }
  }

  .documents_wrap {
    padding-top: calc(32px - 8px);
  }
}
