:root {
  --heightScreen: 100vh;
}

.app-tag {
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #fafafa);

  color: var(--neutral-7, #8c8c8c);

  padding-left: 8px;
  padding-right: 8px;

  /* Footnote/description */
  font-family: Noto Sans SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */

  &.disabled {
    color: var(--neutral-7, #8c8c8c);
  }
}

.layout_component {
  box-sizing: content-box;
  height: var(--heightScreen);
}

.DownloadFile {
  cursor: pointer;
}

.ant-btn-dashed {
  border-color: transparent !important;
}

.ant-dropdown {
  .ant-btn {
    font-size: 14px;
  }
}

.html-truncate {
  overflow: hidden;

  P {
    margin: 2px 0;
  }

  ol,
  ul {
    margin: 2px 0;
  }
}

.ant-picker {
  width: 100%;
}

.custom_search {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  padding: 5px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .wrap_btns {
    border-top: 1px solid #f0f0f0;
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
  }
}

.ql-editor {
  background-color: #fff;
}

.ql-formats button {
  width: 18px !important;
  height: 18px !important;
}

.ql-formats svg {
  width: 12px;
  height: 12px;
}

.ql-disabled .ql-editor {
  background-color: rgba(0, 0, 0, 0.04);
}

.btn_download span {
  color: #1677ff !important;
}
