:root {
    --body-regular-font-family: "NotoSans SC-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0px;
    --body-regular-line-height: 22px;
    --character-secondary-45: rgba(0, 0, 0, 0.45);
    --character-secondary-25: rgba(0, 0, 0, 0.25);
    --characterdisabled-placeholder-25: rgba(0, 0, 0, 0.25);
    --charactertitle-85: rgba(0, 0, 0, 0.85);
    --character-title-85: rgba(0, 0, 0, 0.85);
    --footnote-description-font-family: "NotoSans SC-Regular", Helvetica;
    --footnote-description-font-size: 12px;
    --footnote-description-font-style: normal;
    --footnote-description-font-weight: 400;
    --footnote-description-letter-spacing: 0px;
    --footnote-description-line-height: 20px;
    --h5-regular-font-family: "NotoSans SC-Regular", Helvetica;
    --h5-regular-font-size: 16px;
    --h5-regular-font-style: normal;
    --h5-regular-font-weight: 400;
    --h5-regular-letter-spacing: 0px;
    --h5-regular-line-height: 24px;
    --hit-box: rgba(255, 255, 255, 0);

    --neutral-1: rgba(255, 255, 255, 1);
    --neutral-2: #fafafa;
    --neutral-4: rgba(240, 240, 240, 1);
    --neutral-6: #bfbfbf;
    --neutral-7: #8c8c8c;
    --neutral-9: #434343;
    --neutral-13: rgba(0, 0, 0, 1);

    --primary: #004ad7;
    --primary-6: rgba(24, 144, 255, 1);

    --variable-collection-background-and-fields-secondary: rgba(236, 242, 247, 1);
    --variable-collection-background-main: rgba(255, 255, 255, 1);
    --variable-collection-BG: rgba(255, 255, 255, 1);
    --variable-collection-chosen: rgba(237, 150, 81, 0.15);

    --character-disabled-placeholder-25: rgba(0, 0, 0, 0.25);

    --pnk-color-3: #ffedd4;
    --pnk-color-6: #f09f54;

    --sunset-orange-6: #fa8c16;

    --character-danger: #ff4d4f;
    --dust-red-1: #fff1f0;

    --Gray-3: #828282;
}
