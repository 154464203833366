.modal_view_contracts {

  .ant-modal-content {
    border-radius: 10px !important;

    .ant-modal-title {
      font-weight: 400 !important;
      font-size: 24px !important;
      line-height: 83% !important;
    }
  }


  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 133%;

    margin-bottom: 4px;
  }

  &__info_right {
    text-align: right;
  }

  &__delimiter {
    width: 100%;
    border-bottom: 1px dashed #E0E0E0;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 400;
    font-size: 16px;

    padding-bottom: 6px;

    &.last {
      padding-bottom: 20px;
      margin-bottom: 12px;

      border-bottom: 1px dashed #E0E0E0;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      & > span {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .modal_view_contracts__info_right {
        text-align: left;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &__info_title {
    display: inline-block;
    width: 120px;
  }

  &__info_size {
    font-weight: bold;
  }


  .ant-collapse-header {
    padding: 6px 0 !important;
    padding-bottom: 4px !important;
  }

  .ant-collapse-content-box {
    padding: 0 0 5px 25px !important;

    .DownloadFile {
      font-weight: 400;
      font-size: 16px;
      color: var(--primary);
    }
  }

  &__record_title {
    font-size: 16px;

    &.bold {
      font-weight: 600;
    }

    display: flex;
    justify-content: space-between;

    .title_contract {
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;
    }
  }

  &__record_title2 {
    font-weight: 400;

    &.bold {
      font-weight: 600;
    }

    font-size: 16px;

    display: flex;
    justify-content: space-between;

    img {
      width: 20px;
      position: relative;
      top: 5px;
      left: 10px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      & > span {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__invoice_title {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);

    display: flex;
    justify-content: space-between;
  }

  &__record_content {
    display: grid;
    grid-template-columns: 1fr auto auto;
    padding-bottom: 4px;

    font-weight: 400;
    font-size: 16px;

    color: rgba(0, 0, 0, 0.56);
  }

  &__record_files {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div + div {
      margin-top: 4px;
    }
  }

  &__invoice_body {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__invoice_line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.56);
  }

  &__record_delete, &__invoice_delete {
    svg {
      color: var(--character-danger);
    }
  }

  .has_error {
    color: red;
  }

  &__record_edit, .has_warning {
    color: #F08C19;
  }
}
