@import '../../assets/sizes';

.canvas_table {
  @media screen and (max-width: $mobileWidthMedia) {
    overflow: hidden;
  }
}

.ModalAddRow,
.ModalApproveBudget,
.ModalConfirmBudgetMark,
.ModalSendApprove,
.ModalSettings,
.ModalHistory,
.ModalWrap,
.ModalSelectContragents,
.ModalContragent {
  position: absolute;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  padding: 8px;
  background: #fff;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  & > span {
    padding: 12px 8px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;

    color: #000;
    font-family:
      ALS Wagon,
      serif;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  button {
    border-radius: 4px;
    margin-right: 16px;
  }
}

.ModalApproveBudget {
  width: 600px;
  padding: 24px;
  grid-gap: 32px;

  #modalApproveCanvas {
    height: 75vh;
    margin-bottom: -40px;
    margin-right: -10px;

    .grid-scrollable {
      overflow: auto;
    }
  }

  @media screen and (max-width: $mobileWidthMedia) {
    width: 100vw;
    margin-top: 0;
    box-sizing: border-box;

    #modalApproveCanvas {
      margin-bottom: -20px;
    }
  }
}

.ModalContragent {
  width: 500px;
  padding: 32px;
  grid-gap: 32px;
  background-color: #f0f1f6;
  box-sizing: border-box;

  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      text-align: left;
      width: 264px !important;
      min-width: 264px !important;
      display: block;

      color: #000;
      font-family:
        ALS Wagon,
        serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .ant-form-item-required::before {
        display: none !important;
      }
    }
  }



  @media screen and (max-width: $mobileWidthMedia) {
    overflow: auto;

    .ant-picker,
    #basic_price,
    #basic_size,
    #basic_sum_wat {
      width: 50% !important;
    }
  }
}

.ModalContranet__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModalSendApprove {
  padding: 24px;
  grid-gap: 32px;
  width: 350px;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
}

.ModalConfirmBudgetMark {
  width: 410px;
  padding: 24px;

  .btn_wrap {
    padding-top: 32px;
    display: flex;

    button {
      margin-right: 12px;
    }
  }
}

.ModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: 0;

  .ModalInvoice {
    padding: 10px;
  }

  .ModalWellBill {
    padding: 10px;
  }
}

.ModalSettings {
  padding: 24px;
  width: 284px;

  .title {
    padding-bottom: 10px;
  }

  .line {
    display: flex;
    align-items: center;
    padding: 0 0 0;
    font-size: 16px;
    cursor: pointer;
    grid-gap: 10px;
  }

  .buttons {
    padding-top: 10px;
  }
}

.ModalHistory {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--heightScreen);
  padding: 0;

  box-sizing: border-box;
  background: #f0f1f6;

  .title {
    color: #000;
    font-family: 'ALS Wagon', serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12px;
  }

  .ant-select-selection-placeholder {
    color: black;
  }

  .wrap_filters {
    .mobile-main-filters {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 8px;
      padding-left: 12px;
      padding-right: 12px;

      .ant-select {
        height: 40px;
      }

      button {
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        &.dots:before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: var(--Red, #eb5757);
          margin: 0 auto;

          position: absolute;
          transform: translate(18px, -18px);
        }
      }
    }

    .modal_wrap_filters {
      padding-left: 19px;
      padding-right: 24px;

      .filters_first {
        display: grid;
        grid-template-columns: 1fr 1fr 3fr;

        grid-gap: 8px;

        margin-bottom: 8px;
      }

      .filters_second {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 3fr;

        grid-gap: 8px;
      }

      &.mobile {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f0f1f6;
        width: 100vw;
        height: var(--heightScreen);

        box-sizing: border-box;

        z-index: 1000;

        .ant-select {
          height: 40px;
        }

        .filters_first {
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-gap: 24px;

          padding: 0 12px 12px;
        }

        .filters_second {
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-gap: 24px;

          padding: 0 12px;
        }

        .footer {
          //position: absolute;
          left: 12px;
          margin-top: calc(100vh - 600px);
        }
      }
    }
  }

  .ModalHistoryTable {
    height: calc(var(--heightScreen) - 150px);
    overflow: auto;
    padding-left: 24px;
    padding-right: 24px;

    .ant-table-content {
      min-width: 600px;
    }

    .ant-table-cell-scrollbar {
      opacity: 0;
    }

    @media screen and (max-width: $mobileWidthMedia) {
      padding-left: 12px;
      height: calc(var(--heightScreen) - 160px);
    }

    .ant-table-measure-row td {
      padding: 0 !important;
    }

    .ant-table-thead .ant-table-cell {
      border-bottom: 1px solid var(--Borders, #d1d2d6) !important;
    }

    .ant-table-row:nth-child(2) td {
      border-top: none !important;
    }
  }

  .iconClear {
    cursor: pointer;
    font-size: 12px;
    padding: 14px;
    z-index: 1000;
    width: 20px;

    position: relative;
    top: -15px;
    left: -15px;
  }
}

.ModalSelectDate {
  .anticon-info-circle {
    display: none;
  }
}

.ModalConnectNomenclature {
  .ant-tree {
    background-color: transparent;
  }
}

.ModalSelectContragents {
  position: absolute;
  background-color: #fff;
  width: 200px;
  padding: 0;
  grid-gap: 1px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    //padding: 8px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.SuperEditor {
  display: none;

  input,
  .ant-picker,
  .ant-select {
    padding-right: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    max-width: calc(100vw - 10px);

    font-family: 'ALS Wagon Regular', serif;
    font-size: 18px;
    font-weight: 400;

    &.number {
      text-align: right;
    }
  }

  .ant-select {
    padding-right: 2px;

    &.ant-select-focused {
      .ant-select-selector {
        border-color: #000 !important;
        box-shadow: none !important;
      }
    }

    .ant-select-selector {
      border-radius: 0 !important;
      padding-top: 5px;
      padding-bottom: 5px;

      .ant-select-selection-overflow {
        flex-wrap: nowrap;
      }
    }
  }

  .ant-picker {
    margin-left: 2px;
    border-radius: 0 !important;
    border-color: #000;
  }

  .multiSelect {
    .ant-select-selection-item {
      background-color: transparent !important;
    }
  }

  &__error {
    border: 1px solid red;
  }
}

.btn_delete_action {
  display: flex;
  height: 20px;
  align-items: center;
}

.modal-import-file,
.modal-many-lines {
  .ant-modal-confirm-content {
    max-width: 800px;
    width: 459px;
  }

  .selectFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc(32px - 8px);
    margin-bottom: 32px;
    cursor: pointer;

    .label {
      color: #000;
      font-family:
        ALS Wagon,
        serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.4;
    }

    .fileName {
      color: #004ad7;
      font-family:
        ALS Wagon,
        serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 8px 16px;
      border-radius: 4px;
      background: rgba(0, 74, 215, 0.06);
    }

    @media screen and (max-width: $mobileWidthMediaMini) {
      flex-direction: column;
      align-items: flex-start;

      .label {
        margin-bottom: 10px;
      }
    }
  }

  .line_1 {
    color: var(--Gray-1, #333);
    font-family:
      ALS Wagon,
      serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding-top: calc(32px - 8px);
    padding-bottom: calc(32px - 8px);
  }

  .line_error {
    color: var(--Red, #eb5757);
    font-family:
      ALS Wagon,
      serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 32px;
    margin-bottom: calc(32px - 8px);
  }

  .line-counter {
    display: flex;
    padding-top: calc(32px - 8px);
    padding-bottom: calc(32px);
    width: 200px;

    input {
      background-color: transparent;
      border-color: transparent;
      text-align: center;
      width: 60px;

      color: #000;
      font-family:
        ALS Wagon,
        serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    button {
      height: 40px;
      min-width: 40px;
      padding: 0;
    }
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}

.modal-many-lines {
  .ant-btn.ant-btn-sm span {
    position: relative;
    left: 4px;
  }
}

.tableCanvas .cheetah-grid {
  border-top: 1px solid var(--Borders, #d1d2d6);
}

.tableCanvas__info {
  padding-left: 15px;
  font-size: 20px;
  padding-bottom: 15px;

  &_item {
    display: flex;
    align-items: center;
    grid-gap: 5px;

    span {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);

      display: flex;
      align-items: center;
    }
  }
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}


.ant-select-dropdown .ant-select-item {
  &:has(.green) {
    background-color: #BBFFCE !important;

    &:hover {
      background-color: #71e391 !important;
    }
  }

  &:has(.yellow) {
    background-color: #fff7af !important;

    &:hover {
      background-color: #fded61 !important;
    }
  }


}
