$black: rgb(50, 50, 50) !important;

.ContragentList {
  .wrap_table-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .wrap_table {
    width: 100%;
    max-width: 1250px;
    box-sizing: border-box;

    .contragent-filter.mobile-filter {
      padding-left: 16px;

      display: flex;
      flex-direction: row;

      .contragent-filter-icon {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .ant-tree-list-holder-inner {
      background-color: #FFFFFF;
      align-items: stretch;
    }

    .ant-tree-treenode.ant-tree-treenode-switcher-open {
      &:has(.tree-contragent-title.grey) {
        border-top: none;
      }
    }

    div.ant-tree-treenode.ant-tree-treenode-switcher-open {
      &:has(.contragent-first-child-contragent-last-child) {
        border-top: solid 1px #D1D2D6 !important;
        ;
      }
    }

    div.ant-tree-treenode.ant-tree-treenode-switcher-open {
      &:has(.contragent-first-child) {
        border-top: solid 1px #D1D2D6 !important;
      }
    }


    .ant-tree-list-holder-inner .ant-tree-treenode.ant-tree-treenode-switcher-close {
      border-bottom: none;
    }

    .ant-tree-list-holder-inner .ant-tree-treenode.ant-tree-treenode-switcher-close:last-child {
      border-bottom: solid 1px #D1D2D6;
    }

    .ant-tree-switcher:hover,
    .ant-tree-node-content-wrapper:hover {
      background-color: transparent;
    }

    .ant-tree-list-holder-inner {

      .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close,
      .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
        box-sizing: border-box;
        width: 100%;
      }

      .ant-tree-treenode {
        .ant-tree-indent {
          display: none;
        }

        border-top: solid 1px #D1D2D6;
        border-bottom: solid 1px #D1D2D6;
        margin-top: -1px;
      }

      .ant-tree-switcher.ant-tree-switcher {
        margin: 8px;
        padding: 12px 16px;
        margin-right: 0;
      }

      .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper {
        padding: 0;
      }

      .ant-tree-switcher.ant-tree-switcher-noop {
        display: none;
      }

      .ant-tree-treenode.ant-tree-treenode-switcher-close {
        &:has(.color-green) {
          .ant-tree-switcher.ant-tree-switcher {
            background-color: #D4FFE2;
          }
        }
        &:has(.color-black) {
          .ant-tree-switcher.ant-tree-switcher {
            background-color: $black;
            color: white;
          }
        }

        &:has(.contragent-last-child) {
          margin-bottom: 8px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:has(.contragent-first-child-contragent-last-child) {
          margin-top: 8px;
          margin-bottom: 8px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

      }

      .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last:has(.level1) {
        background-color: #F0F1F6;
      }

      .ant-tree-treenode.ant-tree-treenode-switcher-open,
      .ant-tree-treenode.ant-tree-treenode-switcher-close {
        background-color: #F0F1F6;

        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:has(.contragent-first-child) {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          margin-top: 8px;
        }

        padding-left: 15px;
        margin-top: 0;

        &:has(.level2) {
          height: 72px;
          margin-left: 8px;
          margin-right: 8px;
          border-left: solid 1px #D1D2D6;
          border-right: solid 1px #D1D2D6;
          border-bottom: none;
        }

        &:has(.contragent-central-child) {
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-of-type:has(.level2) {
          border-right: solid 1px #D1D2D6;
        }
      }
      .ant-tree-treenode-switcher-open,
      .ant-tree-treenode-switcher-close {
        &:has(.color-yellow) {
          background-color: #FFF7AF;
        }

        &:has(.color-white) {
          background-color: transparent !important;
        }

        &:has(.color-green) {
          background-color: #D4FFE2;
        }

        &:has(.color-black) {
          background-color: $black;
          color: white;

          .tree-contragent-img-arr {
            path {
              fill: white;
            }
          }
        }

        &:has(.level2) {
          padding-left: 16px;
        }

        &:has(.contragent-first-child-contragent-last-child) {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          margin-top: 8px;
        }
      }

      .ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
        border-bottom: solid 1px #D1D2D6;
        &:has(.contragent-last-info) {
          margin-bottom: 8px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      .ant-space-item {
        color: black;
        font-size: 15px;
        width: 20px;
        white-space: nowrap;
      }



      div.tree-contragent.level2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between
      }

      .ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
        &:has(.level3) {
          .tree-contragent {
            padding: 16px 16px;
          }

          display: flex;
          align-content: stretch;
          align-items: stretch;
          margin-left: 8px;
          margin-right: 8px;
          border: solid 1px #D1D2D6;
        }
      }


      .ant-tree-switcher.ant-tree-switcher_close,
      .ant-tree-switcher.ant-tree-switcher_open {
        margin: 0;
        padding: 0;
        margin-top: 14px;
        margin-right: 14px;
      }

      .ant-tree-node-selected {
        background-color: initial;
      }

      .tree-contragent-text-title {
        display: flex;
        flex-direction: row;

        .tree-contragent-text.title-grey {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.48px;
          color: #828282;
        }
      }


      .level2 {
        div {
          max-width: 80%;

          .tree-contragent-title {
            max-width: 100%;
            margin: 0;
            padding-right: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

        }
      }

      .ant-tree-node-content-wrapper {
        height: 100%;
        overflow: hidden;
      }


      .tree-contragent-title.grey {
        color: #828282;
      }

      .tree-contragent-img-arr {
        width: 20px;
        height: 20px;
      }

      .ant-tree-switcher-icon {
        transition: transform 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
      }

      .ant-tree-switcher-icon.open {
        transform: rotate(90deg);
      }

      .tree-contragent-text-title {
        height: 20px;
      }

      .tree-contragent.level3 {
        p {
          margin: 8px 0;
        }

        .text-comments,
        .text-contacts,
        .tree-contragent-text-tags,
        .tree-contragent-text-adress {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 9;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .tree-contragent-text-title .tree-contragent-text.title-grey {
        margin: 0;
      }


      .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last {
        background-color: none;
      }

      .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper {
        .tree-contragent.level1 {
          padding: 12px 16px 12px 0;
          margin-left: 0;
        }

        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper {
          height: 23.990px;
        }

        .tree-contragent.level2 {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding: 12px 16px 12px 0;
          margin-left: 0;
          padding-left: 0;
          height: 23.990px;
          max-width: 100%;
        }

        .tree-contragent.level2.color-yellow {
          background-color: #FFF7AF;
        }

        .tree-contragent.level2.color-green {
          background-color: #D4FFE2;
        }

        .tree-contragent.level2.color-balck {
          background: none;
        }

        .tree-contragent.level2.color-white {
          background: transparent !important;
        }
      }

      .wrapper-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .tree-contragent {
          padding: 12px 16px;
          margin-right: auto;
        }

        .wrapper-item-circle {
          padding: 12px 16px;
          margin-left: auto;
          display: flex;
          gap: 4px;

          .circle {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            justify-content: center;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .circle.circle-yellow {
            background-color: #FFF7AF;
          }

          .circle.circle-black {
            background-color: black;
            color: #FFFFFF;
          }

          .circle.circle-green {
            background-color: #D4FFE2;
          }

          .circle.circle-grey {
            background-color: #FFFFFF;
          }
        }
      }
    }

    .ant-table-wrapper .ant-table-pagination.ant-pagination {
      margin: 12px 0;
    }
  }

  .no_contragents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }

  .contragent_name {
    color: #004ad7;
    cursor: pointer;
  }

  .rating_column_1,
  .ant-table-cell-row-hover.rating_column_1 {
    color: rgba(255, 255, 255, 0.8) !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .rating_column_2,
  .ant-table-cell-row-hover.rating_column_2 {
    color: black !important;
    background-color: #fff7af !important;
  }

  .rating_column_3,
  .ant-table-cell-row-hover.rating_column_3 {
    color: black !important;
    background-color: #bbffce !important;
  }

  .ant-table-wrapper {
    min-width: 1200px;

    colgroup {
      col:nth-child(1) {
        min-width: 50px;
        width: 100px !important;
        max-width: 100px;

        @media screen and (max-width: $mobileWidthMedia) {
          width: 50px !important;
        }
      }

      col:nth-child(2) {
        min-width: 200px;
        width: auto !important;
      }

      col:nth-child(3) {
        min-width: 160px;
        width: auto !important;
      }

      col:nth-child(4) {
        min-width: 85px;
        width: 100px !important;
        max-width: 100px;

        @media screen and (max-width: $mobileWidthMedia) {
          width: 85px !important;
        }
      }

      col:nth-child(5) {
        min-width: 135px;
        width: auto !important;
      }

      col:nth-child(6) {
        min-width: 135px;
        width: auto !important;
      }

      col:nth-child(7) {
        min-width: 135px;
        width: auto !important;
      }

      col:nth-child(8) {
        min-width: 135px;
        width: auto !important;
      }
    }
  }

  .ant-table-header,
  .ant-table-body {
    colgroup col:nth-child(2) {
      //min-width: 200px;
      //width: 100% !important;
    }
  }

  .ant-table-tbody {
    tr:first-child td {
      padding: 0 !important;
    }
  }

  .ant-table-cell {
    vertical-align: top;
  }

  .ant-table-filter-column {
    align-items: flex-start;

    .ant-dropdown-trigger {
      padding: 4px 3px;
      margin-block: 0;
      margin-inline: 0;
    }
  }
}

.create_contragent_modal {
  .actions .ant-row .ant-col {
    text-align: right;
  }

  .ant-input-borderless,
  .ant-picker-borderless input,
  .ant-select-borderless .ant-select-selection-item {
    color: black !important;
    cursor: auto !important;
  }

  .ant-picker-borderless .ant-picker-suffix,
  .ant-select-borderless .ant-select-arrow {
    display: none;
  }

  .ant-select-borderless .ant-select-selector,
  .ant-select-borderless input {
    background-color: transparent !important;
    cursor: auto !important;
  }

  .dangerouslySetInnerHTML {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.onlyView {
    label::before {
      display: none !important;
    }
  }

  .contragent-file-list {
    display: flex;
    justify-content: space-between;

    padding-top: 5px;

    &>div {
      cursor: pointer;
      color: var(--primary-6);
    }

    &>span {
      cursor: pointer;
      font-size: 15px;
      color: var(--character-danger);
    }
  }

  .btn-add-nomenclature {
    float: right;
    margin-top: -60px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .create_contragent_modal {
    margin: 0;
    padding: 0;

    .ant-modal {
      top: 0;
      margin: 0;
      padding: 0;
      max-width: 100%;
      width: 100% !important;
      height: 100vh !important;
    }
  }
}

@media (max-width: 614px) {
  .create_contragent_modal {
    margin: 0;
    padding: 0;
    max-width: 100vw;

    .ant-modal {
      top: 0;
      margin: 0;
      padding: 0;
      max-width: 100%;
      width: 100%;
      height: 100vh !important;
    }

    .ant-row {
      align-items: flex-end;
    }

    #doc_date_help .ant-form-item-explain-error {
      position: absolute;
    }

    .requisites_add_btn {
      float: left;
    }
  }
}

@media (max-width: 400px) {
  .create_contragent_modal {
    #doc_number_help, #doc_date_help {
      min-height: 44px;
    }
  }
}




.wrappe-modal-mobile-filter {
  margin: 0;

  .ant-modal-content {
    background-color: #FFFF !important;

    .ant-modal-footer {
      .btn-reset {
        background-color: #EB575714;
        color: #EB5757;
      }
    }

    .ant-tag {
      height: 28px;
      display: flex;
      text-align: center;
      flex-direction: row;
      align-items: center;
    }

  }

  .ant-modal {
    margin: 0;
    width: 100% !important;
    max-width: 100%;
  }

  .ant-modal-header {

    .ant-modal-title {
      background-color: #FFFF !important;
    }
  }

  .modal-mobile-filter {
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding-top: 0;

    .mobile-filter-tags {
      margin-bottom: 24px;
    }

    .filter-tag {
      border-radius: 4px;
      border: solid 1px #F0F1F6;
    }
  }
}

.modal-add-contract__warning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 8px;

  img {
    padding-top: 5px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    color: #100f1f;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: #eb5757;

    padding-bottom: 16px;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    justify-content: flex-end;
  }
}
