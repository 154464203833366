.NomenclaturesList {
  .ant-table th, .ant-table td {
    border-left: 1px transparent !important;
    border-right: 1px transparent !important;

  }

  .nomenclatures-table {
    .ant-table-measure-row td {
      padding: 0 !important;
    }

    .ant-table-thead {
      font: 14px ALS Wagon Bold;

      .ant-table-cell {
        height: 45px;
      }
    }

    th.ant-table-cell-fix-left {
      background-color: #f0f1f6 !important;
    }

    .ant-table-cell {
      border: 0.5px solid #D1D2D6 !important;
      padding: 4px 8px !important;

      &:has(.section) {
        background-color: #D7F1FF;
      }

      &:has(.work_type) {
        background-color: #F0F1F6;
      }

      &:has(.nomenclature) {
        background-color: #F0F1F6;
      }

      &:has(input.ant-input, .ant-select) {
        padding: 0 !important;
      }

      .nomenclatures-table__row_number {
        display: flex;
        justify-content: space-between;

        span {
          opacity: 0.8;

          svg {
            cursor: pointer;
          }

          &.disabled {
            opacity: 0.4;

            svg {
              cursor: default;
            }
          }
        }

      }

      .nomenclatures-table__title {
        &.ant-input.section {
          padding-left: 30px;
        }

        &.ant-input.work_type {
          padding-left: 50px;
        }

        &.ant-input.nomenclature {
          padding-left: 50px;
        }

        &.icon {
          width: 20px;
          height: 20px;
        }

        .icon-share {
          width: 15px;
          height: 15px;
          padding-left: 5px;
          position: relative;
          top: 4px;
        }

        &-text {
          display: inline-block;
          width: 100%;
        }

        &.region {
          display: flex;
          justify-content: space-between;
          align-content: center;
          grid-gap: 5px;

          img {
            width: 15px;
            cursor: pointer;
          }
        }
      }

      .nomenclatures-table__actions {
        position: relative;
        top: 3px;
      }
    }


    &__title {
      display: flex;
      align-items: center;

      &.work_type, &.add-work-type {
        padding-left: 20px;
      }

      &.nomenclature, &.add-nomenclature {
        padding-left: 40px;
      }

      &.add-nomenclature, &.add-work-type, &.add-section {
        color: #1D5FDB;
      }
    }

    &__title_dots {
      margin-left: auto;

      img:hover {
        background-color: rgba(128, 128, 128, 0.1);
      }
    }

    input.ant-input {
      transition: none !important;
      border-radius: 0 !important;
      height: 38px;
      border: 1px solid transparent;

      &[readonly] {
        background-color: rgba(198, 198, 198, 0.01);
      }

      &:focus {
        border: 1px solid black !important;
      }
    }

    .ant-select {
      width: 100%;
      height: 38px;

      .ant-select-selector {
        transition: none !important;
        border-radius: 0 !important;
        border: 1px solid transparent;

        &[readonly] {
          background-color: rgba(198, 198, 198, 0.01);
        }

        &:hover {
          border: 1px solid transparent !important;
        }
      }

      &.ant-select-focused .ant-select-selector {
        border: 1px solid black !important;
      }
    }
  }
}

.nomenclatures-table__popover .ant-popover-inner {
  padding: 0 !important;
  position: relative;
  top: 35px;
  left: 5px;

  .ModalAddRow {
    width: 200px;
  }
}
