
.fin_controller_screen {

  &__content {
    padding-left: 20px;
    padding-right: 20px;
    height: calc(100vh - var(--visibleHeaderHeight) + 120px);
     overflow: hidden !important; 
    .ant-tabs {
      height: inherit;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tabpane {
      height: inherit;
    }

    .ant-tabs-tab {
      padding: 5px 10px;
      height: 30px;

      color: #000;

      & + .ant-tabs-tab {
        margin-left: 10px;
      }
    }

    .ant-tabs-tab-active {
      background-color: #004AD7;
      border-radius: 6px;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }

    &.FC-hidden{
      height: auto !important;
    }
    
    .ant-tabs-ink-bar {
      display: none;
    }

    #rc-tabs-1-tab-more a {
      color: #000 !important;
    }
    
    .ant-tree {
      background-color: #f0f1f6;

      span:hover {
        background-color: transparent !important;
      }
    }

    .ant-tree-title {
      font-weight: 400;
      font-size: 16px;
    }

    .ant-tree-node-content-wrapper {
      width: 100%;
    }

    .ant-tree-treenode {
      padding-right: 5px;
      margin-right: 5px;
      width: 100%;

      padding-top: 6px;
      padding-bottom: 6px;
    }

    .ant-tree-treenode-selected {
      background-color: #004AD7 !important;
      color: white;

      .ant-tree-node-selected {
        background-color: #004AD7 !important;
      }

    }

    .ant-tree-indent, .ant-tree-switcher-noop {
      //display: none;
    }
  }

  &__search {
    width: 240px;
    border-radius: 6px;
  }

  &__status {
    background: #dde4f4;
    border: 1px solid rgba(0, 74, 215, 0.4);
    border-radius: 4px;
    padding: 1px 6px;
    width: max-content;
  }

  &__change_status {

    .ant-dropdown-menu {
      border-radius: 6px;

      .ant-dropdown-menu-title-content {
        font-size: 16px;

        & > .ant-menu-root {
          border: none;
        }
      }

      .ant-menu-submenu-title {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 16px;

        width: 100%;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }

      .ant-dropdown-menu-item-active {
        .ant-menu-submenu-title {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }

  &__empty {
    padding: 10px;
  }

  &__export {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    border-top: 1px solid #d1d2d6;
    background: #f0f1f6;

    font-weight: 400;
    font-size: 16px;


    .ant-btn {
      border-radius: 6px;
    }
  }
}
.tabsDropDownSelect.fin_controller_screen__content{
  height: calc(100vh - var(--visibleHeaderHeight) + 120px - 47px);
}
.FC-wrapper {
  display: flex;
  flex-direction: row;
  height: inherit;

  .FC-tree {
    width: 20%;
    border-top: 1px solid var(--Borders, #d1d2d6);
    border-right: 1px solid var(--Borders, #d1d2d6);
  }

  .ant-table-row-expand-icon-cell button {
    position: relative;
    left: 40px;
    z-index: 100;
  }

  .ant-table-selection-column label {
    position: relative;
    left: -40px;
  }

  .ant-table-cell {
    font-size: 16px;
  }

  tr .ant-table-cell:nth-child(2) {
    border-left: none !important;
  }

  tr .ant-table-cell:nth-child(3) {
    border-left: none !important;
  }

  tr th.ant-table-cell:nth-child(3) span {
    position: relative;
    left: -40px;
  }

  //номенклатура
  .FC-table-nomenclature{
    background: rgba(0, 0, 0, 0.04);

    &:has(.ant-table-cell){
      /*     background-color: #D1D2D6; */

      .ant-table-wrapper {
        position: relative;
        top: 2px;
      }

      .ant-table-thead tr:first-child th {
        border-top: none !important;

      }

      .ant-table-tbody{
        /*       background-color: red; */
      }
    }
    .ant-table-cell {
      background: #f0f1f6;

      &:has(.FC-table-nomenclature){
        padding: 0 !important;
      }

    }


    .ant-table-wrapper.ant-table{
      /*   background-color: blue; */
    }
    padding-left: 50px;
    //номенклатура-шапка
    .ant-table-thead{
      border-left: none;
      border-right: none;

      //номенклатура-шапка-ячейки
      .ant-table-cell{
        border-left: none;
        border-right: none;
      }
      ::before{
        display: none;
      }
    }


    //номенклатура-тело
    .ant-table-tbody{

    }
  }

  .ant-table-wrapper tr.ant-table-expanded-row >td{
    padding: 0 !important;
  }
  .fin-controller_table {
    width: 80%;

    .fin-controller_table__header {
      display: flex;
      flex-direction: row;
      gap: 5px;

      span {
        border: solid 1px grey;

      }
    }
  }

  .fin-controller_table__pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding-top: 16px;

    .ant-pagination-item-active {
      background: rgba(0, 0, 0, 0.04);
      border: none;

      a {
        font-weight: 400;
        font-size: 16px;
        color: #000;
      }
    }
  }
}
